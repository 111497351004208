import {
  Flex,
  HStack,
  Text,
  Link,
  Icon,
  IconButton,
  useColorModeValue,
  chakra,
  useDisclosure,
  HTMLChakraProps,
  useColorMode,
  useUpdateEffect
} from '@chakra-ui/react'
import React, { useRef, useState } from 'react'
import { GithubIcon } from './icons'
import { FaYoutube, FaMoon, FaSun, FaLinkedinIn } from 'react-icons/fa'
import { TbCertificate } from 'react-icons/tb'
import Search from './omni-search'
import SponsorButton from './sponsor-button'
import { MobileNavButton, MobileNavContent } from './mobile-nav'
import { useViewportScroll } from 'framer-motion'
import { useEffect } from 'react'
import { EntestLogo } from 'components/entest-logo'
import siteConfig from 'configs/site-config.json'

const NavbarContent = () => {
  const mobileNav = useDisclosure()
  const { toggleColorMode: toggleMode } = useColorMode()
  const text = useColorModeValue('dark', 'light')
  const SwitchIcon = useColorModeValue(FaMoon, FaSun)
  const mobileNavBtnRef = useRef<HTMLButtonElement>()

  useUpdateEffect(() => {
    mobileNavBtnRef.current?.focus()
  }, [mobileNav.isOpen])

  return (
    <>
      <Flex
        width={'100%'}
        height={'100%'}
        // bg={'teal.100'}
        // minH={'60px'}
        align={'center'}
        justifyContent={'space-between'}
        px={'6'}
      >
        <Flex
          align={'center'}
          // bg={'green.200'}
          minW={'4rem'}
          width={'auto'}
          mr={'5'}
        >
          {/* <NextLink href={'/'} passHref> */}
          {/* <chakra.a display="block" aria-label="Chakra UI, Back to homepage">
              <Logo display={{ base: 'none', md: 'block' }} />
              <Box minW="3rem" display={{ base: 'block', md: 'none' }}>
                <LogoIcon />
              </Box>
            </chakra.a> */}
          <EntestLogo></EntestLogo>
          {/* </NextLink> */}
        </Flex>
        <Flex
          align={'center'}
          maxW={'1100px'}
          // maxW={'900px'}
          justify="flex-end"
          w={'100%'}
          color={'gray.400'}
          // bg={'orange.100'}
        >
          <Search></Search>
          <Text
            width={'auto'}
            flexShrink={0}
            display={{ base: 'none', md: 'none' }}
            pr={'5'}
          >
            Version
          </Text>
          <HStack spacing={'3'} display={{ base: 'none', md: 'flex' }}>
            <Link isExternal href={siteConfig.repo.url} aria-label="github">
              <Icon
                as={GithubIcon}
                display={'block'}
                transition="color 0.2s"
                w="5"
                h="5"
                _hover={{ color: 'gray.600' }}
                color={useColorModeValue('black', 'white')}
              ></Icon>
            </Link>
            <Link isExternal href={siteConfig.youtube} aria-label="discord">
              <Icon
                as={FaYoutube}
                display={'block'}
                transition="color 0.2s"
                w="5"
                h="5"
                _hover={{ color: 'gray.600' }}
                color={useColorModeValue('black', 'white')}
              ></Icon>
            </Link>
            <Link isExternal href={siteConfig.linkedin} aria-label="youtube">
              <Icon
                as={FaLinkedinIn}
                display={'block'}
                transition="color 0.2s"
                w="5"
                h="5"
                _hover={{ color: 'gray.600' }}
                color={useColorModeValue('black', 'white')}
              ></Icon>
            </Link>
            <Link isExternal href={siteConfig.credly} aria-label="credly">
              <Icon
                as={TbCertificate}
                display={'block'}
                transition="color 0.2s"
                w="5"
                h="5"
                _hover={{ color: 'gray.600' }}
                color={useColorModeValue('black', 'white')}
              ></Icon>
            </Link>
          </HStack>
          <HStack spacing={'3'}>
            <IconButton
              size="sm"
              fontSize={'lg'}
              aria-label={`Switch to ${text} mode`}
              //colorScheme={useColorModeValue('purple', 'orange')}
              //color={useColorModeValue('white', 'black')}
              color={useColorModeValue('black', 'white')}
              ml={{ base: '0', md: '3' }}
              icon={<SwitchIcon></SwitchIcon>}
              onClick={toggleMode}
            ></IconButton>
            <SponsorButton ml="0"></SponsorButton>
            <MobileNavButton
              ref={mobileNavBtnRef}
              aria-label="Open Menu"
              onClick={mobileNav.onOpen}
            ></MobileNavButton>
          </HStack>
        </Flex>
      </Flex>
      <MobileNavContent isOpen={mobileNav.isOpen} onClose={mobileNav.onClose} />
    </>
  )
}

export const Navbar = (props: HTMLChakraProps<'header'>) => {
  const { maxW = '6xl', maxWidth = '6xl' } = props
  const ref = useRef<HTMLHeadingElement>()
  const [y, setY] = useState(0)
  const { height = 0 } = ref.current?.getBoundingClientRect() ?? {}

  const { scrollY } = useViewportScroll()
  useEffect(() => {
    return scrollY.onChange(() => setY(scrollY.get()))
  }, [scrollY])

  return (
    <chakra.header
      ref={ref}
      shadow={y > height ? 'sm' : undefined}
      transition="box-shadow 0.2s, background-color 0.2s"
      pos="sticky"
      top="0"
      zIndex="3"
      bg="white"
      _dark={{ bg: 'gray.800' }}
      left="0"
      right="0"
      width="full"
      {...props}
    >
      <chakra.div
        // height="4.5rem"
        height="3.5rem"
        mx="auto"
        maxW={maxW}
        maxWidth={maxWidth}
      >
        <NavbarContent></NavbarContent>
      </chakra.div>
    </chakra.header>
  )
}

export default Navbar
