import { Box, BoxProps } from '@chakra-ui/react'
import React from 'react'

function CodeContainer(props: BoxProps) {
  return (
    <Box
      padding="5"
      rounded="6px"
      my="8"
      bg="#011627"
      {...props}
      fontSize={'xs'}
    />
  )
}

export default CodeContainer
