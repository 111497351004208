import {
  MdFormatSize,
  MdTextFormat,
  MdOutlineImage,
  MdInsertEmoticon,
  MdHorizontalRule,
  MdSwapVert,
  MdStarBorder,
  MdOutlineCheckBoxOutlineBlank,
  MdEditAttributes,
  MdWarning,
  MdNotes,
  MdInput,
  MdAddBox,
  MdSearch,
  MdPassword,
  MdSmartphone,
  MdToggleOff,
  MdCheckBox,
  MdRadioButtonChecked,
  MdGridView,
  MdTableRows,
  MdSplitscreen,
  MdWebAsset,
  MdExpand,
  MdTableChart,
  MdMoreHoriz,
  MdLink,
  MdMenu,
  MdTab,
  MdTouchApp,
  MdUnfoldMore,
  MdDisabledVisible,
  MdRefresh,
  MdTune,
  MdSystemUpdateAlt,
  MdCheckCircle
} from 'react-icons/md'

export interface ComponentNavItem {
  href: string
  label: string
  body: string
  tertiary?: boolean
  order?: number
}

const sortByLabel = (a: ComponentNavItem, b: ComponentNavItem) =>
  a.label < b.label ? -1 : a.label > b.label ? 1 : 0

const sortByOrder = (a: ComponentNavItem, b: ComponentNavItem) =>
  a.order < b.order ? -1 : a.order > b.order ? 1 : 0

export const programmingComponents: ComponentNavItem[] = [
  {
    href: '/programming/golang-basic',
    label: 'go 1: basics',
    body: `Golang Basics`,
    platforms: ['react'],
    order: 1
  },
  {
    href: '/programming/golang-http',
    label: 'go 3: http',
    body: `Golang HTTP Web Server`,
    platforms: ['react'],
    order: 3
  },
  {
    href: '/programming/golang-postgresql',
    label: 'go 2: postgresql',
    body: `Golang PostgreSQL`,
    platforms: ['react'],
    order: 2
  },
  {
    href: '/programming/golang-lambda',
    label: 'go 4: lambda',
    body: `prisma and nextjs`,
    platforms: ['react'],
    order: 4
  },
  {
    href: '/programming/golang-stable-diffusion',
    label: 'go 5: diffusion',
    body: `stable diffusion`,
    platforms: ['react'],
    order: 5
  },
  {
    href: '/programming/golang-bedrock-wo-client',
    label: 'go 6: bedrock wo sdk',
    body: `bedrock wo sdk`,
    platforms: ['react'],
    order: 6
  },
  {
    href: '/programming/golang-aoss',
    label: 'go 7: opensearch',
    body: `opensearch client`,
    platforms: ['react'],
    order: 7
  },
  {
    href: '/programming/go-web-demo',
    label: 'go 8: web app',
    body: `go and postgresql`,
    platforms: ['react'],
    order: 8
  },
  {
    href: '/programming/golang-channel',
    label: 'go 9: channel',
    body: `concurrency and channel`,
    platforms: ['react'],
    order: 9
  },
  {
    href: '/programming/postgresql-aws',
    label: 'postgresql 2',
    body: `PostgreSQL on AWS`,
    platforms: ['react'],
    order: 10
  },
  {
    href: '/programming/postgresql-local',
    label: 'postgresql 1',
    body: `PostgreSQL on Local Machine`,
    platforms: ['react'],
    order: 11
  },
  {
    href: '/programming/php-hello',
    label: 'php 1: Hello',
    body: `Hello PHP and MariaDB`,
    platforms: ['react'],
    order: 12
  },
  {
    href: '/programming/php-swin-1',
    label: 'php 2: LAMP',
    body: `Setup LAMP Stack on Amazon EC2`,
    platforms: ['react'],
    order: 13
  },
  {
    href: '/programming/php-swin-2',
    label: 'php 3: Photo',
    body: `Build a photo album with php and mariadb`,
    platforms: ['react'],
    order: 14
  },
  {
    href: '/programming/basic-ts',
    label: 'ts 1: basic',
    body: `Basic TypeScript`,
    platforms: ['react'],
    order: 15
  },
  {
    href: '/programming/ts-s3',
    label: 'ts 2: s3',
    body: `TypeScript S3 SDK`,
    platforms: ['react'],
    order: 16
  },
  {
    href: '/programming/ts-cognito',
    label: 'ts 3: cognito',
    body: `TypeScript Cognito SDK`,
    platforms: ['react'],
    order: 17
  },
  {
    href: '/programming/node-hello',
    label: 'ts 4: http server',
    body: `Hello Node and PostgreSQL`,
    platforms: ['react'],
    order: 18
  },
  {
    href: '/programming/ts-bedrock',
    label: 'ts 5: bedrock',
    body: `bedrock and nextjs`,
    platforms: ['react'],
    order: 19
  },
  {
    href: '/programming/ts-prisma',
    label: 'ts 6: prisma',
    body: `prisma and nextjs`,
    platforms: ['react'],
    order: 20
  },
  {
    href: '/programming/ts-deploy-lambda',
    label: 'ts 7: deploy lambda',
    body: `prisma and nextjs`,
    platforms: ['react'],
    order: 21
  },
  {
    href: '/programming/ts-async-sdx',
    label: 'ts 8: invoke sdx',
    body: `prisma and nextjs`,
    platforms: ['react'],
    order: 22
  },
  {
    href: '/programming/ts-vercel-ai-bedrock',
    label: 'ts 9: vercel bedrock',
    body: `prisma and nextjs`,
    platforms: ['react'],
    order: 23
  },
  {
    href: '/programming/ts-cognito-basic',
    label: 'ts 10: basic cognito',
    body: `basic cognito in typescript`,
    platforms: ['react'],
    order: 24
  },
  {
    href: '/programming/ts-bedrock-wo-sdk',
    label: 'ts 11: bedrock wo sdk',
    body: `bedorck wo sdk`,
    platforms: ['react'],
    order: 25
  },
  {
    href: '/programming/ts-s3-wo-sdk',
    label: 'ts 12: s3 wo sdk',
    body: `s3 wo sdk`,
    platforms: ['react'],
    order: 26
  },
  {
    href: '/programming/nginx-1',
    label: 'nginx 1: httpd',
    body: `nginx httpd`,
    platforms: ['react'],
    order: 27
  }
].sort(sortByOrder)

export const terraformComponents: ComponentNavItem[] = [
  {
    href: '/terraform/workspace',
    label: 'Terraform Workspace',
    body: `Terraform`,
    platforms: ['react'],
    order: 1
  },
  {
    href: '/terraform/module',
    label: 'Terraform Module',
    body: `Terraform`,
    platforms: ['react'],
    order: 2
  },
  {
    href: '/terraform/hello',
    label: 'Terraform Hello',
    body: `Terraform`,
    platforms: ['react'],
    order: 3
  },
  {
    href: '/terraform/variables',
    label: 'Terraform Variables',
    body: `Terraform`,
    platforms: ['react'],
    order: 4
  },
  {
    href: '/terraform/cf-1-lambda',
    label: 'cf 1: lambda',
    body: `CloudFormation`,
    platforms: ['react'],
    order: 5
  },
  {
    href: '/terraform/cf-2-lambda-apigw',
    label: 'cf 2: apigw',
    body: `CloudFormation`,
    platforms: ['react'],
    order: 6
  },
  {
    href: '/terraform/cf-3-lambda-adot',
    label: 'cf 3: adot',
    body: `CloudFormation`,
    platforms: ['react'],
    order: 7
  },
  {
    href: '/terraform/cf-4-xray',
    label: 'cf 4: xray',
    body: `CloudFormation`,
    platforms: ['react'],
    order: 8
  },
  {
    href: '/terraform/cf-5-cognito',
    label: 'cf 5: cognito',
    body: `CloudFormation`,
    platforms: ['react'],
    order: 9
  },
  {
    href: '/terraform/cf-6-ecs',
    label: 'cf 6: ecs',
    body: `CloudFormation`,
    platforms: ['react'],
    order: 10
  },
  {
    href: '/terraform/cf-7-ecs-blue-green',
    label: 'cf 7: ecs blue green',
    body: `CloudFormation`,
    platforms: ['react'],
    order: 11
  },
  {
    href: '/terraform/cf-9-eks',
    label: 'cf 9: eks',
    body: `CloudFormation`,
    platforms: ['react'],
    order: 12
  }
].sort(sortByOrder)

export const webComponents: ComponentNavItem[] = [
  {
    href: '/web/float',
    label: 'css 1: float',
    body: `web`,
    platforms: ['react'],
    order: 1
  },
  {
    href: '/web/flexbox',
    label: 'css 2: flex box',
    body: `web`,
    platforms: ['react'],
    order: 2
  },
  {
    href: '/web/home',
    label: 'css 3: home',
    body: `web`,
    platforms: ['react'],
    order: 3
  },
  {
    href: '/web/position',
    label: 'css 4: position',
    body: `web`,
    platforms: ['react'],
    order: 4
  },
  {
    href: '/web/sidebar',
    label: 'css 5: sidebar',
    body: `web`,
    platforms: ['react'],
    order: 5
  },
  {
    href: '/web/hello-tailwind',
    label: 'css 6: hello tailwind',
    body: `web`,
    platforms: ['react'],
    order: 6
  },
  {
    href: '/web/responsive',
    label: 'css 7: responsive',
    body: `web`,
    platforms: ['react'],
    order: 7
  },
  {
    href: '/web/modal',
    label: 'css 8: modal',
    body: `web`,
    platforms: ['react'],
    order: 8
  },
  {
    href: '/web/form',
    label: 'css 9: form',
    body: `web`,
    platforms: ['react'],
    order: 9
  },
  {
    href: '/web/color',
    label: 'css 10: color',
    body: `web`,
    platforms: ['react'],
    order: 10
  },
  {
    href: '/web/common',
    label: 'css 11: common',
    body: `web`,
    platforms: ['react'],
    order: 11
  },
  {
    href: '/web/review-books',
    label: 'css 12: review books',
    body: `web`,
    platforms: ['react'],
    order: 12
  },
  {
    href: '/web/hero-section',
    label: 'css 13: hero section',
    body: `web`,
    platforms: ['react'],
    order: 13
  },
  {
    href: '/web/tailwind-toggle-theme',
    label: 'css 14: toggle theme',
    body: `web`,
    platforms: ['react'],
    order: 14
  },
  {
    href: '/web/nextjs-auth-amplify-cognito-hosted-ui',
    label: 'auth 1: cognitoui',
    body: `web`,
    platforms: ['react'],
    order: 15
  },
  {
    href: '/web/nextjs-auth-server-side',
    label: 'auth 2: serverside',
    body: `web`,
    platforms: ['react'],
    order: 16
  },
  {
    href: '/web/react-amplify-auth-flow',
    label: 'auth 3: amplify',
    body: `web`,
    platforms: ['react'],
    order: 17
  },
  {
    href: '/web/next-auth-bare',
    label: 'auth 4: nextauth',
    body: `web`,
    platforms: ['react'],
    order: 18
  },
  {
    href: '/web/next-auth-full',
    label: 'auth 5: detail',
    body: `web`,
    platforms: ['react'],
    order: 19
  },
  {
    href: '/web/nextauth-amplify-domain',
    label: 'auth 6: domain',
    body: `web`,
    platforms: ['react'],
    order: 20
  },
  {
    href: '/web/nextjs-mdx-app-router',
    label: 'mdx 1: next mdx',
    body: `web`,
    platforms: ['react'],
    order: 21
  },
  {
    href: '/web/nextjs-contentlayer',
    label: 'mdx 2: next mdx',
    body: `web`,
    platforms: ['react'],
    order: 22
  },
  {
    href: '/web/nextjs-mdx-contentlayer-syntax',
    label: 'mdx 3: next mdx',
    body: `web`,
    platforms: ['react'],
    order: 23
  },
  {
    href: '/web/aws-sdk-ts',
    label: 'aws sdk: typescript',
    body: `web`,
    platforms: ['react'],
    order: 24
  },
  {
    href: '/web/nextjs-tailwind',
    label: 'next 1: nextjs tailwind',
    body: `web`,
    platforms: ['react'],
    order: 27
  },
  {
    href: '/web/nextjs-form-upload',
    label: 'next 2: form upload',
    body: `web`,
    platforms: ['react'],
    order: 28
  },
  {
    href: '/web/next-font',
    label: 'next 3: font',
    body: `web`,
    platforms: ['react'],
    order: 29
  },
  {
    href: '/web/next-cloudfront-cookie',
    label: 'next 4: cookie CF',
    body: `web`,
    platforms: ['react'],
    order: 30
  },
  {
    href: '/web/nextjs-polly',
    label: 'next 5: polly',
    body: `web`,
    platforms: ['react'],
    order: 31
  },
  {
    href: '/web/pirf-challenge',
    label: 'next 6: pirf challenge',
    body: `web`,
    platforms: ['react'],
    order: 32
  },
  {
    href: '/web/nextjs-docker',
    label: 'next 7: deploy docker',
    body: `web`,
    platforms: ['react'],
    order: 33
  },
  {
    href: '/web/nextjs-prisma',
    label: 'next 8: prisma',
    body: `web`,
    platforms: ['react'],
    order: 34
  },
  {
    href: '/web/next-appsync-demo',
    label: 'appsync 1: next',
    body: `web`,
    platforms: ['react'],
    order: 35
  },
  {
    href: '/web/appsync-bedrock',
    label: 'appsync 2: bedrock',
    body: `web`,
    platforms: ['react'],
    order: 36
  },
  {
    href: '/web/nextjs-amplify-bare',
    label: 'amplify 1: auth storage',
    body: `web`,
    platforms: ['react'],
    order: 37
  },
  {
    href: '/web/next-amplify-s3',
    label: 'amplify 2: storage',
    body: `web`,
    platforms: ['react'],
    order: 38
  },
  {
    href: '/web/amplify-hosting',
    label: 'amplify 3: hosting',
    body: `web`,
    platforms: ['react'],
    order: 39
  },
  {
    href: '/web/ecs-chat-app',
    label: 'deploy 1: ecs',
    body: `web`,
    platforms: ['react'],
    order: 40
  },
  {
    href: '/web/ecs-next-apps',
    label: 'deploy 2: ecs',
    body: `web`,
    platforms: ['react'],
    order: 41
  },
  {
    href: '/web/flask-polly',
    label: 'flask 1: polly',
    body: `web`,
    platforms: ['react'],
    order: 42
  },
  {
    href: '/web/radix-ui',
    label: 'react: radix ui',
    body: `web`,
    platforms: ['react'],
    order: 43
  },
  {
    href: '/web/react-component',
    label: 'react: component',
    body: `web`,
    platforms: ['react'],
    order: 44
  }
].sort(sortByOrder)

export const eksComponents: ComponentNavItem[] = [
  {
    href: '/eks/container-anatomy',
    label: 'eks 1: container anatomy',
    body: `eks`,
    platforms: ['react'],
    order: 1
  },
  {
    href: '/eks/kubernetes-components',
    label: 'eks 2: kube components',
    body: `eks`,
    platforms: ['react'],
    order: 2
  },
  {
    href: '/eks/launch-eks-cluster',
    label: 'eks 3: eks cluster',
    body: `eks`,
    platforms: ['react'],
    order: 3
  },
  {
    href: '/eks/eks-fargate-profile',
    label: 'eks 4: fargate profile',
    body: `eks`,
    platforms: ['react'],
    order: 4
  },
  {
    href: '/eks/eks-auto-scaling',
    label: 'eks 5: auto scaling',
    body: `eks`,
    platforms: ['react'],
    order: 5
  },
  {
    href: '/eks/cluster-authentication',
    label: 'eks 6: authentication',
    body: `eks`,
    platforms: ['react'],
    order: 6
  },
  {
    href: '/eks/service-account',
    label: 'eks 7: service account',
    body: `eks`,
    platforms: ['react'],
    order: 7
  },
  {
    href: '/eks/eks-observability',
    label: 'eks 8: eks observability',
    body: `eks`,
    platforms: ['react'],
    order: 8
  },
  {
    href: '/eks/eks-prometheus',
    label: 'eks 9: eks prometheus',
    body: `eks`,
    platforms: ['react'],
    order: 9
  },
  {
    href: '/eks/eks-cluster',
    label: 'eks 10: eks cluster',
    body: `eks`,
    platforms: ['react'],
    order: 10
  },
  {
    href: '/eks/eks-fargate',
    label: 'eks 11: eks fargate',
    body: `eks`,
    platforms: ['react'],
    order: 11
  },
  {
    href: '/eks/eks-next-app',
    label: 'eks 12: next app',
    body: `eks`,
    platforms: ['react'],
    order: 12
  },
  {
    href: '/eks/eks-https',
    label: 'eks 13: eks https',
    body: `eks`,
    platforms: ['react'],
    order: 13
  },
  {
    href: '/eks/eks-flux',
    label: 'eks 14: eks flux',
    body: `eks`,
    platforms: ['react'],
    order: 14
  },
  {
    href: '/eks/ecs-blue-green',
    label: 'ecs 1: blue green',
    body: `ecs`,
    platforms: ['react'],
    order: 15
  },
  {
    href: '/eks/adot-opentelemetry-1',
    label: 'observe 1: adot',
    body: `observability`,
    platforms: ['react'],
    order: 16
  },
  {
    href: '/eks/container',
    label: 'eks 15: container',
    body: `container`,
    platforms: ['react'],
    order: 22
  },
  {
    href: '/eks/persistent-volume',
    label: 'eks 16: storage',
    body: `persistent volume`,
    platforms: ['react'],
    order: 23
  },
  {
    href: '/eks/ingress',
    label: 'eks 17: ingress',
    body: `ingress`,
    platforms: ['react'],
    order: 24
  },
  {
    href: '/eks/eks-cf-demo',
    label: 'eks 18: cf',
    body: `eks in cloudformation`,
    platforms: ['react'],
    order: 25
  },
  {
    href: '/eks/ckad-part-1',
    label: 'ckad part 1',
    body: `eks`,
    platforms: ['react'],
    order: 37
  },
  {
    href: '/eks/ckad-part-2',
    label: 'ckad part 2',
    body: `eks`,
    platforms: ['react'],
    order: 38
  },
  {
    href: '/eks/ckad-part-3',
    label: 'ckad part 3',
    body: `eks`,
    platforms: ['react'],
    order: 39
  },
  {
    href: '/eks/ckad-part-4',
    label: 'ckad part 4',
    body: `eks`,
    platforms: ['react'],
    order: 40
  },
  {
    href: '/eks/ckad-part-5',
    label: 'ckad part 5',
    body: `eks`,
    platforms: ['react'],
    order: 41
  }
].sort(sortByOrder)

export const swiftuiComponents: ComponentNavItem[] = [
  {
    href: '/swiftui/food-day-1',
    label: 'Food Truck Day 1',
    body: `SwiftUI`,
    platforms: ['react']
  },
  {
    href: '/swiftui/bubble-chat',
    label: 'Bubble Chat',
    body: `SwiftUI`,
    platforms: ['react']
  },
  {
    href: '/swiftui/network-request',
    label: 'Network Request',
    body: `SwiftUI`,
    platforms: ['react']
  },
  {
    href: '/swiftui/graphql',
    label: 'GraqphQL',
    body: `SwiftUI`,
    platforms: ['react']
  },
  {
    href: '/swiftui/auth-flow',
    label: 'Amplify Auth',
    body: `SwiftUI`,
    platforms: ['react']
  }
].sort(sortByLabel)

export const serverlessComponents: ComponentNavItem[] = [
  {
    href: '/serverless/nextjs-aws-serverless',
    label: 'Nextjs Serverless',
    body: `build a simple nextjs app with backend serverless`,
    platforms: ['react']
  },
  {
    href: '/serverless/eventbridge-github',
    label: 'EventBridge GitHub',
    body: `EventBridge and GitHub WebHook`,
    platforms: ['react']
  },
  {
    href: '/serverless/apigw-socket',
    label: 'API Gateway Socket',
    body: `Introduction to API Gateway Socket`,
    platforms: ['react']
  },
  {
    href: '/serverless/apigw-integration',
    label: 'API Gateway Integration',
    body: `Introduction to API Gateway`,
    platforms: ['react']
  },
  {
    href: '/serverless/apigw-sqs-lambda',
    label: 'ApiGw SQS Lambda',
    body: `Async Invoke Lambda with Api Gateway and SQS`,
    platforms: ['react']
  },
  {
    href: '/serverless/lambda-graviton-x86',
    label: 'Lambda Graviton',
    body: `Benchmark performance between Lambda Graviton and x86`,
    platforms: ['react']
  },
  {
    href: '/serverless/dynamodb-stream-lambda',
    label: 'DyanmoDB Stream',
    body: `DynamoDB stream trigger lambda`,
    platforms: ['react']
  },
  {
    href: '/serverless/apigw-auth-cognito',
    label: 'API Gateway Auth',
    body: 'Api Gateway Auth with Cogntio JWT and Lambda',
    platforms: ['react']
  },
  {
    href: '/serverless/eventbridge',
    label: 'EventBridge',
    body: 'EventBridge with Lambda Producer and Consumer ',
    platforms: ['react']
  },
  {
    href: '/serverless/stepfunctions-getting-started',
    label: 'Stepfunctions',
    body: 'Getting Started with Stepfunctions',
    platforms: ['react']
  },
  {
    href: '/serverless/deploy-lambda',
    label: 'Deploy Lambda',
    body: 'Explore different ways to deploy a lambda in CDK',
    platforms: ['react']
  },
  {
    href: '/serverless/serverless-demo',
    label: 'Serverless Demos',
    body: 'Serverless Demos',
    platforms: ['react']
  },
  {
    href: '/serverless/s3-replication',
    label: 'S3 Replication',
    body: 'S3 Replicate Existing Objects',
    platforms: ['react']
  },
  {
    href: '/serverless/next-polly-image',
    label: 'Next Polly App',
    body: 'next polly image app',
    platforms: ['react']
  },
  {
    href: '/serverless/next-diffusion-app',
    label: 'Stable Diffusion App',
    body: 'next stable diffusion app',
    platforms: ['react']
  },
  {
    href: '/serverless/s3-signed-url',
    label: 's3 signed url',
    body: 's3 signed url',
    platforms: ['react']
  }
].sort(sortByLabel)

export const dataComponents: ComponentNavItem[] = [
  {
    href: '/data/data-lake',
    label: 'dat 1: data lake',
    body: 'build a data lake demo',
    platforms: ['react'],
    order: 1
  },
  {
    href: '/data/athena-hello',
    label: 'dat 2: athena intro',
    body: 'Getting Started with Athena',
    platforms: ['react'],
    order: 2
  },
  {
    href: '/data/athena-glue-access',
    label: 'dat 3: athena glue access',
    body: 'athena and glue data access',
    platforms: ['react'],
    order: 3
  },
  {
    href: '/data/glue-interactive-session',
    label: 'dat 4: glue notebook',
    body: 'glue interactive session',
    platforms: ['react'],
    order: 4
  },
  {
    href: '/data/glue-demo',
    label: 'dat 5: glue workflow',
    body: 'Getting Started with Glue Workflow',
    platforms: ['react'],
    order: 5
  },
  {
    href: '/data/lakeformation-intro',
    label: 'dat 6: lakeformation intro',
    body: 'lakeformation introduction',
    platforms: ['react'],
    order: 6
  },
  {
    href: '/data/redshift-hello',
    label: 'dat 7: redsfhit cluster',
    body: 'Getting Started with Redsfhit',
    platforms: ['react'],
    order: 7
  },
  {
    href: '/data/kinesis-studio-notebook',
    label: 'dat 8: kinesis flink',
    body: 'getting started with kinesis data analytics',
    platforms: ['react'],
    order: 8
  },
  {
    href: '/data/kinesis-serverless',
    label: 'dat 9: kinesis serverless',
    body: 'kinesis serverless',
    platforms: ['react'],
    order: 9
  },
  {
    href: '/data/msk-kda',
    label: 'dat 10: msk kinesis',
    body: 'getting started with msk',
    platforms: ['react'],
    order: 10
  },
  {
    href: '/data/msk-serverless',
    label: 'dat 11: msk serverless',
    body: 'getting started with msk serverless',
    platforms: ['react'],
    order: 11
  },
  {
    href: '/data/delta-lake-upsert',
    label: 'dat 12: delta lake',
    body: 'getting started with delta on aws',
    platforms: ['react'],
    order: 12
  }
].sort(sortByOrder)

export const dlComponents: ComponentNavItem[] = [
  {
    href: '/dl/nano-chatgpt',
    label: 'model 1: nanoGPT',
    body: 'deep learning',
    platforms: ['react'],
    order: 1
  },
  {
    href: '/dl/sm-deploy-model',
    label: 'model 2: deploy',
    body: 'deploy models on sagemaker',
    platforms: ['react'],
    order: 2
  },
  {
    href: '/dl/stable-diffusion',
    label: 'model 3: sdx',
    body: 'deep learning',
    platforms: ['react'],
    order: 2
  },
  {
    href: '/dl/deploy-sdx-async',
    label: 'model 4: async sdx',
    body: 'deep learning',
    platforms: ['react'],
    order: 2
  },
  {
    href: '/dl/distributed-training',
    label: 'train 1: distributed',
    body: 'deep learning',
    platforms: ['react'],
    order: 3
  },
  {
    href: '/dl/appsync-bedrock',
    label: 'app 1: asy bedrock',
    body: 'deep learning',
    platforms: ['react'],
    order: 4
  },
  {
    href: '/dl/lambda-web-adapter-bedrock',
    label: 'app 2: lambda bedrock',
    body: 'deep learning',
    platforms: ['react'],
    order: 5
  },
  {
    href: '/dl/flask-bedrock',
    label: 'app 3: flask bedrock',
    body: 'deep learning',
    platforms: ['react'],
    order: 6
  },
  {
    href: '/dl/langchain-prompt',
    label: 'app 4: lch prompt',
    body: 'deep learning',
    platforms: ['react'],
    order: 7
  },
  {
    href: '/dl/oss-day-1',
    label: 'app 5: oss 1',
    body: 'opensearch server 1',
    platforms: ['react'],
    order: 8
  },
  {
    href: '/dl/oss-day-2',
    label: 'app 6: oss 2',
    body: 'opensearch server 2',
    platforms: ['react'],
    order: 9
  },
  {
    href: '/dl/aoss-day-1',
    label: 'app 7: aoss 1',
    body: 'opensearch serverless 1',
    platforms: ['react'],
    order: 10
  },
  {
    href: '/dl/aoss-day-2',
    label: 'app 8: aoss 2',
    body: 'opensearch langchain',
    platforms: ['react'],
    order: 12
  },
  {
    href: '/dl/vercel-bedrock-chat-image',
    label: 'app 9: vercel bedrock',
    body: 'vercel bedrock chat and image generation',
    platforms: ['react'],
    order: 12
  },
  {
    href: '/dl/lambda-url-streaming-response',
    label: 'app 10: lambda url',
    body: 'lambda url streaming response',
    platforms: ['react'],
    order: 13
  },
  {
    href: '/dl/go-bedrock-app',
    label: 'app 11: go bedrock 1',
    body: 'go bedrock',
    platforms: ['react'],
    order: 14
  },
  {
    href: '/dl/go-bedrock-demo',
    label: 'app 12: go bedrock 2',
    body: 'go bedrock',
    platforms: ['react'],
    order: 15
  }
].sort(sortByOrder)

export const mlComponents: ComponentNavItem[] = [
  {
    href: '/ml/sagemaker-studio',
    label: 'day 1: sagemaker studio',
    body: 'sagemaker studio',
    platforms: ['react'],
    order: 1
  },
  {
    href: '/ml/sm-data-wrangler',
    label: 'day 2: data wrangler',
    body: 'sagemaker wrangler',
    platforms: ['react'],
    order: 2
  },
  {
    href: '/ml/sm-clarify',
    label: 'day 3: sagemaker clarify',
    body: 'sagemaker wrangler',
    platforms: ['react'],
    order: 3
  },
  {
    href: '/ml/sg-feature-store',
    label: 'day 4: feature store',
    body: 'sagemaker feature store',
    platforms: ['react'],
    order: 4
  },
  {
    href: '/ml/sg-spark',
    label: 'day 5: sagemaker spark',
    body: 'sagemaker spark',
    platforms: ['react'],
    order: 5
  },
  {
    href: '/ml/sg-experiment',
    label: 'day 6: sg experiment',
    body: 'sagemaker studio',
    platforms: ['react'],
    order: 6
  },
  {
    href: '/ml/pca-sagemaker',
    label: 'day 7: pca sagemaker',
    body: 'Build a Pipeline for PCA in Sagemaker',
    platforms: ['react'],
    order: 7
  },
  {
    href: '/ml/sg-processing-api',
    label: 'day 8: processing api',
    body: 'SageMaker Processing API',
    platforms: ['react'],
    order: 8
  },
  {
    href: '/ml/sg-model',
    label: 'day 9: model registry',
    body: 'SageMaker Model',
    platforms: ['react'],
    order: 9
  },
  {
    href: '/ml/sm-estimator',
    label: 'day 10: estimator',
    body: 'SageMaker Estimator',
    platforms: ['react'],
    order: 10
  },
  {
    href: '/ml/sm-endpoint',
    label: 'day 11: endpoint',
    body: 'SageMaker Endpoint',
    platforms: ['react'],
    order: 11
  },
  {
    href: '/ml/mxnet-lambda',
    label: 'day 12: mxnet lambda',
    body: `Host a ML Model in AWS Lambda`,
    platforms: ['react'],
    order: 12
  },
  {
    href: '/ml/ml-pipeline-part-1',
    label: 'day 13: ml pipeline 1',
    body: `Build a ci/cd pipeline for a mxnet model with sagemaker`,
    platforms: ['react'],
    order: 13
  },
  {
    href: '/ml/ml-pipeline-part-2',
    label: 'day 14: ml pipeline 2',
    body: 'Build a simple ML pipeline with sagemaker pipeline and codepipeline',
    platforms: ['react'],
    order: 14
  },
  {
    href: '/ml/sm-model-monitor',
    label: 'day 15: model monitor',
    body: 'sagemaker model monitor',
    platforms: ['react'],
    order: 15
  },
  {
    href: '/ml/sm-pgvector',
    label: 'day 16: pgvector streamlit',
    body: 'sagemaker pgvector streamlit',
    platforms: ['react'],
    order: 16
  },
  {
    href: '/ml/sm-huggingface-model',
    label: 'day 17: huggingface',
    body: 'deploy huggingface model on sagemaker',
    platforms: ['react'],
    order: 17
  }
].sort(sortByOrder)

export const applicationComponents: ComponentNavItem[] = [
  {
    href: '/application/nextjs-server-side-auth',
    label: 'NextJS Auth',
    body: 'nextjs server side auth',
    platforms: ['react']
  },
  {
    href: '/application/nextjs-cognito-hosted-ui',
    label: 'Cognito Hosted Ui',
    body: 'nextjs auth using amplify cognito hosted ui',
    platforms: ['react']
  },
  {
    href: '/application/opensearch-note',
    label: 'Opensearch Serverless',
    body: 'build a note bookmark with opensearch serverless',
    platforms: ['react']
  },
  {
    href: '/application/react-cognito-polly',
    label: 'React Cognito Polly',
    body: 'build a react app with cognito auth and polly',
    platforms: ['react']
  },
  {
    href: '/application/amplify-nextjs-auth',
    label: 'Amplify Nextjs Auth',
    body: 'build an auth flow with amplify and nextjs',
    platforms: ['react']
  },
  {
    href: '/application/cognito-sdk-js',
    label: 'Cognito SDK JS',
    body: 'Cognito SDK JS for authentication and authorization',
    platforms: ['react']
  },
  {
    href: '/application/basic-vim',
    label: 'Basic Vim',
    body: 'Basic Vim',
    platforms: ['react']
  },
  {
    href: '/application/nvim-lua',
    label: 'Nvim Lua',
    body: 'nvim lua',
    platforms: ['react']
  },
  {
    href: '/application/kendra',
    label: 'Kendra Search',
    body: 'Kendra Search',
    platforms: ['react']
  },
  {
    href: '/application/amplify-hosting-static',
    label: 'Amplify Hosting',
    body: 'Use Amplify to Host a Static Web',
    platforms: ['react']
  },
  {
    href: '/application/aws-sdk-js-browser',
    label: 'AWS-SDK-JS S3',
    body: 'Upload to S3 from Browser using AWS-SDK-JS',
    platforms: ['react']
  },
  {
    href: '/application/amplify-storage',
    label: 'Storage',
    body: 'Upload to S3 using AWS Amplify in NextJS',
    platforms: ['react']
  },
  {
    href: '/application/text-to-speech',
    label: 'Text To Speech',
    body: 'Amplify text to speech api',
    platforms: ['react', 'vue', 'angular']
  },
  {
    href: '/application/text-identification',
    label: 'Text Identification',
    body: 'Amplify text identification api',
    platforms: ['react', 'vue', 'angular']
  },
  {
    href: '/application/contentlayer',
    label: 'Contentlayer',
    body: 'Use contentlayer and chakraui to build personal site with mdx',
    platforms: ['react', 'vue', 'angular']
  }
].sort(sortByLabel)

export const archComponents: ComponentNavItem[] = [
  {
    href: '/arch/sigv4',
    label: 'arch 1: signature v4',
    body: 'sign a request with aws signature v4',
    platforms: ['react'],
    order: 1
  },
  {
    href: '/arch/assume-role',
    label: 'arch 2: assume role',
    body: `hello assume role`,
    platforms: ['react'],
    order: 2
  },
  {
    href: '/arch/basic-rds',
    label: 'arch 3: basic rds',
    body: `rds failover`,
    platforms: ['react'],
    order: 3
  },
  {
    href: '/arch/alb-asg-demo',
    label: 'arch 4: load balancer',
    body: 'application load balancer and auto scaling',
    platforms: ['react'],
    order: 4
  },
  {
    href: '/arch/aurora',
    label: 'arch 5: aurora',
    body: `aurora and load balancer and auto scaling`,
    platforms: ['react'],
    order: 5
  },
  {
    href: '/arch/rds-iops',
    label: 'arch 6: rds iops',
    body: `experiment iops in rds`,
    platforms: ['react'],
    order: 6
  },
  {
    href: '/arch/dax-dynamodb',
    label: 'arch 7: dax dynamodb',
    body: 'dax cache for dynamodb',
    platforms: ['react'],
    order: 7
  },
  {
    href: '/arch/redis-rds',
    label: 'arch 8: redis for rds',
    body: 'elasticache for redis for rds',
    platforms: ['react'],
    order: 8
  },
  {
    href: '/arch/redis-ddb',
    label: 'arch 9: redis for dynamoDB',
    body: 'elasticCache for redis for dynamodb',
    platforms: ['react'],
    order: 9
  },
  {
    href: '/arch/index-dynamodb',
    label: 'arch 10: dynamodb index',
    body: `dynamodb global secondary index`,
    platforms: ['react'],
    order: 10
  },
  {
    href: '/arch/serverless-demo',
    label: 'arch 11: serverless demos',
    body: 'serverless demos',
    platforms: ['react'],
    order: 11
  },
  {
    href: '/arch/s3-lambda',
    label: 'arch 12: lambda trigger s3',
    body: `lambda trigger s3 and ci/cd pipeline`,
    platforms: ['react'],
    order: 12
  },
  {
    href: '/arch/lambda-api',
    label: 'arch 13: lambda based api',
    body: `lambda based api`,
    platforms: ['react'],
    order: 13
  },
  {
    href: '/arch/ecs-fargate',
    label: 'arch 14: ecs fargate',
    body: `develop ecs fargate with typescript`,
    platforms: ['react'],
    order: 14
  },
  {
    href: '/arch/hello-ecs-flask',
    label: 'arch 15: ecs flask app',
    body: 'deploy flask app on ecs',
    platforms: ['react'],
    order: 15
  },
  {
    href: '/arch/vpc-endpoint',
    label: 'arch 16: vpc endpoints 1',
    body: 'vpc endpoints demo',
    platforms: ['react'],
    order: 16
  },
  {
    href: '/arch/vpc-sg-ec2',
    label: 'arch 17: vpc endpoints 2',
    body: 'vpc endpoint, security group and ec2',
    platforms: ['react'],
    order: 17
  },
  {
    href: '/arch/vpc-flowlogs',
    label: 'arch 18: vpc flowlog',
    body: `vpc flow log`,
    platforms: ['react'],
    order: 18
  },
  {
    href: '/arch/transit-gateway-peering',
    label: 'arch 19: tgw region peering',
    body: 'transit gateway region peering',
    platforms: ['react'],
    order: 19
  },
  {
    href: '/arch/transit-gateway-crouter',
    label: 'arch 20: tgw multiple vpc',
    body: 'transit gateway central router',
    platforms: ['react'],
    order: 20
  },
  {
    href: '/arch/transit-gateway-simualted-on-prem',
    label: 'arch 21: tgw and on-premise',
    body: 'transit gateway connect simulated on-premise',
    platforms: ['react'],
    order: 21
  },
  {
    href: '/arch/cicd-integration-test',
    label: 'arch 22: ci/cd pipeline',
    body: 'ci/cd pipeline with integration test',
    platforms: ['react'],
    order: 22
  },
  {
    href: '/arch/cicd-cross-account',
    label: 'arch 23: ci/cd cross account',
    body: `ci/cd pipeline cross acount deploy`,
    platforms: ['react'],
    order: 23
  },
  {
    href: '/arch/cicd-ecr-ssm-tag',
    label: 'arch 24: ci/cd ecr tag',
    body: `ci/cd pipeline to build ecr with ssm tag`,
    platforms: ['react'],
    order: 24
  },
  {
    href: '/arch/cloudfront-s3',
    label: 'arch 25: cloudfront',
    body: `deploy a web with cloudfront, and waf`,
    platforms: ['react'],
    order: 25
  },
  {
    href: '/arch/cloudtrail-cloudwatch',
    label: 'arch 26: cloudTrail-cloudWatch',
    body: `Create a custom resource`,
    platforms: ['react'],
    order: 26
  },
  {
    href: '/arch/cloudwatch',
    label: 'arch 27: cloudwatch lambda',
    body: 'cloudwatch dashboard to monitor lambda',
    platforms: ['react'],
    order: 27
  },
  {
    href: '/arch/cognito',
    label: 'arch 28: basic cognito',
    body: 'basic of cognito',
    platforms: ['react'],
    order: 28
  },
  {
    href: '/arch/basic-efs',
    label: 'arch 29: basic efs',
    body: `setup efs in vpc`,
    platforms: ['react'],
    order: 29
  },
  {
    href: '/arch/organizations-scp',
    label: 'arch 30: organizations scp',
    body: `aws organization and scp`,
    platforms: ['react'],
    order: 30
  },
  {
    href: '/arch/priviledge-escalation',
    label: 'arch 31: priviledge escalation',
    body: `priviledge escalation`,
    platforms: ['react'],
    order: 31
  },
  {
    href: '/arch/custom-resource',
    label: 'arch 32: custom resource',
    body: `create a custom resource`,
    platforms: ['react'],
    order: 32
  },
  {
    href: '/arch/ssm',
    label: 'arch 33: system manager tunnel',
    body: `vscode remote connect to a private ec2`,
    platforms: ['react'],
    order: 33
  },
  {
    href: '/arch/cicd-devicefarm',
    label: 'arch 34: ci/cd device farm',
    body: `setup ci/cd device farm`,
    platforms: ['react'],
    order: 34
  },
  {
    href: '/arch/amplify-iot',
    label: 'arch 35: little amplify iot',
    body: `build an iot application using amplify and iot core`,
    platforms: ['react'],
    order: 35
  },
  {
    href: '/arch/route-53',
    label: 'arch 36: route 53',
    body: `getting started with route 53`,
    platforms: ['react'],
    order: 36
  },
  {
    href: '/arch/api-gateway-lambda',
    label: 'arch 37: apigw and lambda',
    body: 'apigw and lambda integration',
    platforms: ['react'],
    order: 37
  },
  {
    href: '/arch/microsoft-sql-to-rds-sql',
    label: 'arch 38: microsoft sql to rds',
    body: 'microsoft sql to rds',
    platforms: ['react'],
    order: 38
  },
  {
    href: '/arch/microsoft-sql-aurora-migration',
    label: 'arch 40: db migration',
    body: 'microsoft sql to aurora mysql migration',
    platforms: ['react'],
    order: 39
  },
  {
    href: '/arch/lambda-rds-proxy',
    label: 'arch 40: rds proxy',
    body: 'lambda rds proxy',
    platforms: ['react'],
    order: 40
  },
  {
    href: '/arch/s3-multipart-upload',
    label: 'arch 40: s3 upload',
    body: 's3 multipart upload',
    platforms: ['react'],
    order: 40
  },
  {
    href: '/arch/nacl-demo',
    label: 'arch 41: nacl demo',
    body: 'nacl demo',
    platforms: ['react'],
    order: 41
  }
].sort(sortByOrder)

export const baseComponents: ComponentNavItem[] = [
  {
    href: '/components/view',
    label: 'View',
    body: `View is the foundational component for Amplify UI primitives. While View renders a <div> by default, it can render any HTML element and supports styling via props.`,
    platforms: ['react'],
    icon: MdOutlineCheckBoxOutlineBlank
  },
  {
    href: '/components/text',
    label: 'Text',
    body: `The Text primitive is used to display simple strings of text in an interface.`,
    platforms: ['react'],
    icon: MdTextFormat
  },
  {
    href: '/components/heading',
    label: 'Heading',
    body: `This is a separate primitive from Text for semantic purposes. The Heading primitive maps to an <h*> tag, and Text maps to a <p> tag.`,
    platforms: ['react'],
    icon: MdFormatSize
  },
  {
    href: '/components/icon',
    label: 'Icon',
    body: `The icon component displays simple vector graphics for use in other components like Buttons.`,
    platforms: ['react'],
    icon: MdInsertEmoticon
  },
  {
    href: '/components/image',
    label: 'Image',
    body: `The Image primitive can be used to display responsive images.`,
    platforms: ['react'],
    icon: MdOutlineImage
  },
  {
    href: '/components/divider',
    label: 'Divider',
    body: `A Divider creates separations in content. Dividers can help organize content and establish visual rhythm.`,
    platforms: ['react'],
    icon: MdHorizontalRule
  },
  {
    href: '/components/scrollview',
    label: 'ScrollView',
    body: `ScrollView is essentially a View that allows scrolling of its inner contents.`,
    platforms: ['react'],
    icon: MdSwapVert
  }
].sort(sortByLabel)

export const connectedComponents = [
  {
    href: '/connected-components/authenticator',
    label: 'Authenticator',
    body: 'The Authenticator component adds complete authentication flows to your application with minimal boilerplate.',
    platforms: ['react', 'vue', 'angular', 'flutter']
  },
  {
    href: '/connected-components/authenticator/configuration',
    label: 'Configuration',
    platforms: ['react', 'vue', 'angular', 'flutter'],
    tertiary: true
  },
  {
    href: '/connected-components/authenticator/customization',
    label: 'Customization',
    platforms: ['react', 'vue', 'angular', 'flutter'],
    tertiary: true
  },
  {
    href: '/connected-components/authenticator/headless',
    label: 'Headless Usage',
    platforms: ['react', 'vue', 'angular', 'flutter'],
    tertiary: true
  },
  {
    href: '/connected-components/geo',
    label: 'Geo',
    body: 'Amplify UI Geo provides UI components for maps and location search built on top of Amazon Location Service.',
    platforms: ['react']
  }
]

export const dataDisplayComponents = [
  {
    href: '/components/badge',
    label: 'Badge',
    body: `A Badge is a small visual element to denote a status or message about an item. A small, color-coded visual element that contains letters or numbers, that you can use to label, categorize, or organize items.`,
    platforms: ['react'],
    icon: MdCheckCircle
  },
  {
    href: '/components/rating',
    label: 'Rating',
    body: 'The Rating component displays a read-only star rating of a product or service.',
    platforms: ['react'],
    icon: MdStarBorder
  }
].sort(sortByLabel)

export const feedbackComponents: ComponentNavItem[] = [
  {
    href: '/components/alert',
    label: 'Alert',
    body: `An Alert displays a brief, important message in a way that attracts the user's attention without interrupting the user's task. Alerts are typically intended to be read out dynamically by a screen reader.`,
    platforms: ['react'],
    icon: MdWarning
  },
  {
    href: '/components/placeholder',
    label: 'Placeholder',
    body: `The Placeholder component is used to fill out the interface while content is loaded asynchronously.`,
    platforms: ['react'],
    icon: MdNotes
  },
  {
    href: '/components/loader',
    label: 'Loader',
    body: `Loaders provide a visual cue that an action is either processing or awaiting a result. They are used to help the customer understand the system is working to fulfill a request.`,
    platforms: ['react'],
    icon: MdRefresh
  }
].sort(sortByLabel)

export const inputComponents = [
  {
    href: '/components/textareafield',
    label: 'TextArea Field',
    body: `The TextAreaField form primitive can be used allow users to input multiline text content.`,
    platforms: ['react'],
    icon: MdSystemUpdateAlt
  },
  {
    href: '/components/textfield',
    label: 'Text Field',
    body: `The TextField form primitive can be used allow users to input text content.`,
    platforms: ['react'],
    icon: MdInput
  },
  {
    href: '/components/selectfield',
    label: 'Select Field',
    body: 'The SelectField primitive allows you to create a drop-down list.',
    platforms: ['react'],
    icon: MdUnfoldMore
  },
  {
    href: '/components/sliderfield',
    label: 'Slider Field',
    body: `Sliders allow users to quickly select a value within a range. They should be used when the upper and lower bounds to the range are invariable.`,
    platforms: ['react'],
    icon: MdTune
  },
  {
    href: '/components/stepperfield',
    label: 'Stepper Field',
    body: `A StepperField is a number input with buttons to increase or decrease the value.`,
    platforms: ['react'],
    icon: MdAddBox
  },
  {
    href: '/components/searchfield',
    label: 'Search Field',
    body: `SearchField accepts query text for search.`,
    platforms: ['react'],
    icon: MdSearch
  },
  {
    href: '/components/passwordfield',
    label: 'Password Field',
    body: `PasswordField allows users to input passwords, featuring full password manager support and an optional show/hide password button for user convenience - Amplify UI`,
    platforms: ['react'],
    icon: MdPassword
  },
  {
    href: '/components/phonenumberfield',
    label: 'Phone Number Field',
    body: `The PhoneNumberField form primitive allows users to input a phone number`,
    platforms: ['react'],
    icon: MdSmartphone
  },
  {
    href: '/components/switchfield',
    label: 'Switch Field',
    body: `The SwitchField form primitive is a toggleable input type with a checked (on) and unchecked (off) state.`,
    platforms: ['react'],
    icon: MdToggleOff
  },
  {
    href: '/components/radiogroupfield',
    label: 'Radio Group Field',
    body: `A RadioGroupField allows users to select a single option from a list of mutually exclusive options.`,
    platforms: ['react'],
    icon: MdRadioButtonChecked
  },
  {
    href: '/components/checkboxfield',
    label: 'Checkbox Field',
    body: `CheckboxField is used to mark an individual item as selected, or to select multiple items from a list of individual items.`,
    platforms: ['react'],
    icon: MdCheckBox
  },
  {
    href: '/components/togglebutton',
    label: 'Toggle Button',
    body: 'A toggle button represents an on/off state for some configuration, for example switching on/off bold text in a text editor.',
    platforms: ['react'],
    icon: MdEditAttributes
  },
  {
    href: '/components/button',
    label: 'Button',
    body: `The Button primitive is used to trigger an action or event, such as submitting a form, opening a dialog, canceling an action, or performing a delete operation.`,
    platforms: ['react'],
    icon: MdTouchApp
  }
].sort(sortByLabel)

export const layoutComponents = [
  {
    href: '/components/card',
    label: 'Card',
    body: `The Card component can be used to group related pieces of content.`,
    platforms: ['react'],
    icon: MdWebAsset
  },
  {
    href: '/components/collection',
    label: 'Collection',
    body: `A Collection wraps Flex and Grid components, and provides a way to display items in a collection from a data source.`,
    platforms: ['react'],
    icon: MdSplitscreen
  },
  {
    href: '/components/flex',
    label: 'Flex',
    body: `A layout container using Flexbox.`,
    platforms: ['react'],
    icon: MdTableRows
  },
  {
    href: '/components/grid',
    label: 'Grid',
    body: 'A layout container using CSS Grid',
    platforms: ['react'],
    icon: MdGridView
  },
  {
    href: '/components/table',
    label: 'Table',
    body: `The Table primitive provides users with a styled and customizable table element.`,
    platforms: ['react'],
    icon: MdTableChart
  },
  {
    href: '/components/expander',
    label: 'Expander',
    body: `The Expander primitive enables users to expand or collapse a set of sections.`,
    platforms: ['react'],
    icon: MdExpand
  },
  {
    href: '/components/pagination',
    label: 'Pagination',
    body: `Pagination provides navigation to allow customers to move between large sets of content that are distributed across multiple pages.`,
    platforms: ['react'],
    icon: MdMoreHoriz
  }
].sort(sortByLabel)

export const utilityComponents = [
  {
    href: '/components/visuallyhidden',
    label: 'Visually Hidden',
    body: `The Visually Hidden component is used to visually hide content while leaving it available to screen readers.`,
    platforms: ['react'],
    icon: MdDisabledVisible
  }
].sort(sortByLabel)

export const navigationComponents: ComponentNavItem[] = [
  {
    href: '/components/link',
    label: 'Link',
    body: `Links are customizable and themable elements used for Navigation. By default Links render an anchor tag but can be configured to be used with routing libraries.`,
    platforms: ['react'],
    icon: MdLink
  },
  {
    href: '/components/menu',
    label: 'Menu',
    body: `Menu provides an accessible, interactive menu for selecting actions within an application. Dropdown menu is collision-aware and will automatically change location based on available space.`,
    platforms: ['react'],
    icon: MdMenu
  },
  {
    href: '/components/tabs',
    label: 'Tabs',
    body: `Tabs organize content into multiple sections and allow users to navigate between them.`,
    platforms: ['react'],
    icon: MdTab
  }
].sort(sortByLabel)

export const primitiveComponents = [
  {
    components: [
      {
        href: '/components',
        label: 'Overview',
        platforms: ['react']
      }
    ]
  },
  {
    heading: 'Base',
    components: baseComponents
  },
  {
    heading: 'Feedback',
    components: feedbackComponents
  },
  {
    heading: 'Navigation',
    components: navigationComponents
  },
  {
    heading: 'Inputs',
    components: inputComponents
  },
  {
    heading: 'Layout',
    components: layoutComponents
  },
  {
    heading: 'Data display',
    components: dataDisplayComponents
  },
  {
    heading: 'Utilities',
    components: utilityComponents
  }
]

export const legacyComponents = [
  {
    href: '/legacy-components/chatbot',
    label: 'Chatbot',
    body: 'Chatbot automatically renders a complete chat messaging interface that can be used out-of-the-box, or it can be customized using theming support.',
    platforms: ['react', 'vue', 'angular']
  },
  {
    href: '/legacy-components/storage',
    label: 'Storage',
    body: 'A set of components to help interact with S3 storage.',
    platforms: ['react', 'vue', 'angular']
  }
]

export const guides = [
  {
    href: '/guides',
    label: 'Overview'
  },
  {
    href: '/guides/css-in-js',
    label: 'CSS in JS',
    platforms: ['react', 'vue', 'angular']
  },
  {
    href: '/guides/auth-protected',
    label: 'Protected Routes',
    platforms: ['react']
  }
]

export const gettingStarted = [
  {
    href: '/getting-started/introduction',
    label: 'Introduction'
  }
]

export const theming = [
  {
    href: '/theming',
    label: 'Overview'
  },
  {
    href: '/theming/responsive',
    label: 'Responsive',
    platforms: ['react']
  },
  {
    href: '/theming/default-theme',
    label: 'Default Theme',
    platforms: ['react', 'vue', 'angular']
  },
  {
    href: '/theming/default-theme/colors',
    label: 'Colors',
    platforms: ['react', 'vue', 'angular'],
    tertiary: true
  },
  {
    href: '/theming/default-theme/typography',
    label: 'Typography',
    platforms: ['react', 'vue', 'angular'],
    tertiary: true
  },
  {
    href: '/theming/default-theme/sizes',
    label: 'Sizes',
    platforms: ['react', 'vue', 'angular'],
    tertiary: true
  },
  {
    href: '/theming/dark-mode',
    label: 'Dark mode'
  },
  {
    href: '/theming/css-variables',
    label: 'CSS variables',
    platforms: ['react', 'vue', 'angular']
  },
  {
    href: '/theming/style-props',
    label: 'Style Props',
    platforms: ['react']
  }
]

export const DISCORD = 'https://discord.gg/amplify'
export const COMMUNITY = 'https://amplify.aws/community'
export const COMMUNITY_EVENTS = 'https://amplify.aws/community/events'
export const COMMUNITY_POSTS = 'https://amplify.aws/community/posts'
export const COMMUNITY_CONTRIBUTORS =
  'https://amplify.aws/community/contributors'
export const COMMUNITY_NEWSLETTERS = 'https://amplify.aws/community/newsletters'
export const TWITTER = 'https://twitter.com/AWSAmplify'
export const GITHUB = 'https://github.com/aws-amplify'
export const TERMS = 'https://aws.amazon.com/terms/'
export const PRIVACY = 'https://aws.amazon.com/privacy/'
export const MARKETING = 'https://aws.amazon.com/amplify/framework/'
export const AWS_USER_GUIDE =
  'https://docs.aws.amazon.com/amplify/latest/userguide/welcome.html'
export const IOS_REFERENCE = 'https://aws-amplify.github.io/amplify-ios/docs/'
export const ANDROID_REFERENCE =
  'https://aws-amplify.github.io/aws-sdk-android/docs/reference/'
export const JS_REFERENCE = 'https://aws-amplify.github.io/amplify-js/api/'

export const GITHUB_REPO = `${GITHUB}/amplify-ui/`
export const GITHUB_REPO_FILE = `${GITHUB_REPO}/blob/main/`
