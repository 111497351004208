// haimtran 25 jul 2022

import {
  Box,
  Center,
  HStack,
  List,
  ListProps,
  ListItem,
  chakra
} from '@chakra-ui/react'
import { Routes } from 'utils/get-route-context'
import React, { Fragment, ReactElement, ReactNode } from 'react'
import { FaSwift, FaCat, FaAws, FaDog, FaLaptopCode } from 'react-icons/fa'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { SidebarLink } from './sidebar-link'
import {
  SiAwsamplify,
  SiServerless,
  SiTerraform,
  SiKubernetes,
  SiWebpack
} from 'react-icons/si'
import { FaDatabase } from 'react-icons/fa'

export type SidebarContentProps = Routes & {
  pathname?: string
  contentRef?: any
}

export const SidebarContent = ({
  routes,
  pathname,
  contentRef
}: SidebarContentProps) => {
  return (
    <>
      {routes.map((lvl1, idx) => {
        return (
          <Fragment key={idx}>
            {lvl1.heading && (
              <chakra.h4
                fontSize="xs"
                fontWeight="bold"
                my="2"
                textTransform="uppercase"
                letterSpacing="wider"
              >
                {lvl1.title}
              </chakra.h4>
            )}
            {lvl1.routes.map((lvl2, index) => {
              if (!lvl2.routes) {
                return (
                  <SidebarLink
                    key={lvl2.path}
                    href={lvl2.path}
                    ml={'-3'}
                    mt={'1'}
                  >
                    {lvl2.title}
                  </SidebarLink>
                )
              }
            })}
          </Fragment>
        )
      })}
    </>
  )
}

export const mainNavLinks = [
  {
    icon: <FaAws></FaAws>,
    href: '/arch/getting-started',
    label: 'Architect',
    match: (asPath: string, href: string) =>
      href.startsWith('/arch') && asPath.startsWith('/arch')
  },
  {
    icon: <SiServerless></SiServerless>,
    href: '/serverless/next-polly-image',
    label: 'Serverless',
    match: (asPath: string, href: string) =>
      href.startsWith('/serverless') && asPath.startsWith('/serverless')
  },
  {
    icon: <SiAwsamplify></SiAwsamplify>,
    href: '/application/react-cognito-polly',
    label: 'Application',
    match: (asPath: string, href: string) =>
      href.startsWith('/application') && asPath.startsWith('/application')
  },
  {
    icon: <FaSwift></FaSwift>,
    href: '/swiftui/graphql',
    label: 'SwiftUI',
    match: (asPath: string, href: string) =>
      href.startsWith('/swiftui') && asPath.startsWith('/swiftui')
  },
  {
    icon: <FaCat></FaCat>,
    href: '/ml/ml-pipeline-part-1',
    label: 'Machine Learning',
    match: (asPath: string, href: string) =>
      href.startsWith('/ml') && asPath.startsWith('/ml')
  },
  {
    icon: <FaDog></FaDog>,
    href: '/dl/lambda-web-adapter-bedrock',
    label: 'Deep Learning',
    match: (asPath: string, href: string) =>
      href.startsWith('/dl') && asPath.startsWith('/dl')
  },
  {
    icon: <FaDatabase></FaDatabase>,
    href: '/data/lake-formation',
    label: 'Data Engineering',
    match: (asPath: string, href: string) =>
      href.startsWith('/data') && asPath.startsWith('/data')
  },
  {
    icon: <SiTerraform></SiTerraform>,
    href: '/terraform/hello',
    label: 'Terraform',
    match: (asPath: string, href: string) =>
      href.startsWith('/terraform') && asPath.startsWith('/terraform')
  },
  {
    icon: <SiKubernetes></SiKubernetes>,
    href: '/eks/launch-eks-cluster',
    label: 'Amazon EKS',
    match: (asPath: string, href: string) =>
      href.startsWith('/eks') && asPath.startsWith('/eks')
  },
  {
    icon: <SiWebpack></SiWebpack>,
    href: '/web/review-books',
    label: 'Web',
    match: (asPath: string, href: string) =>
      href.startsWith('/web') && asPath.startsWith('/web')
  },
  {
    icon: <FaLaptopCode></FaLaptopCode>,
    href: '/programming/basic-ts',
    label: 'Programming',
    match: (asPath: string, href: string) =>
      href.startsWith('/programming') && asPath.startsWith('/programming')
  }
]

type MainNavLinkProps = {
  href: string
  icon: ReactElement
  children: ReactNode
  label?: string
  isActive: boolean
}

const MainNavLink = ({ href, icon, children, isActive }: MainNavLinkProps) => {
  const router = useRouter()
  const active = router.asPath.startsWith(href) || !!isActive

  return (
    <NextLink href={href} passHref>
      <HStack
        as="a"
        spacing={'3'}
        // fontSize={'xs'}
        fontSize={'xs'}
        fontWeight={active ? 'semibold' : 'medium'}
        color={active ? 'accent' : 'fg-muted'}
        _hover={{ color: active ? undefined : 'fg' }}
      >
        <Center
          w="6"
          h="6"
          borderWidth="1px"
          bg={active ? 'accent-static' : 'transparent'}
          borderColor={active ? 'accent-static' : undefined}
          rounded="base"
          color={active ? 'white' : 'accent'}
        >
          {icon}
        </Center>
        <span>{children}</span>
      </HStack>
    </NextLink>
  )
}

const MainNavLinkGroup = (props: ListProps) => {
  const router = useRouter()

  return (
    <List spacing={'2'} styleType="none" {...props}>
      {mainNavLinks.map(item => (
        <ListItem key={item.label}>
          <MainNavLink
            icon={item.icon}
            href={item.href}
            label={item.label}
            isActive={item.match?.(router.asPath, item.href)}
          >
            {item.label}
          </MainNavLink>
        </ListItem>
      ))}
    </List>
  )
}

export const Sidebar = ({ routes }) => {
  return (
    <Box
      aria-label="sidebar"
      as="nav"
      pos={'sticky'}
      overscrollBehavior="contain"
      top="6.5rem"
      // width={'280px'}
      width={'220px'}
      h="calc(100vh - 6.5rem)"
      pr="8"
      pb="6"
      pl="6"
      pt="4"
      overflow={'auto'}
      className="sidebar-content"
      flexShrink={0}
      display={{ base: 'none', md: 'block' }}
      // fontSize={'sm'}
      // bg="green.100"
    >
      <MainNavLinkGroup mb="5"></MainNavLinkGroup>
      {<SidebarContent routes={routes}></SidebarContent>}
    </Box>
  )
}
