// haimtran 25 jul 2022
// BlogLayout, ComponentLayout and so on

import { PageContainer } from 'components/page-container'
import { Sidebar } from 'components/sidebar/sidebar'
import { Heading } from 'components/page-container'
import React from 'react'
import { RouteItem } from 'utils/get-route-context'
import {
  serverlessComponents,
  applicationComponents,
  archComponents,
  mlComponents,
  swiftuiComponents,
  terraformComponents,
  eksComponents,
  webComponents,
  dataComponents,
  dlComponents,
  programmingComponents
} from 'data/links'

export function getRoutes(slug: string): RouteItem[] {
  const routes = [
    {
      title: 'Architect',
      path: '/arch',
      open: true,
      heading: true,
      sort: true,
      routes: archComponents.map(item => {
        return {
          title: item.label,
          path: item.href
        }
      }),
      summarize: false
    },
    {
      title: 'Serverless',
      path: '/serverless',
      open: true,
      heading: true,
      sort: true,
      routes: serverlessComponents.map(item => {
        return {
          title: item.label,
          path: item.href
        }
      }),
      summarize: false
    },
    {
      title: 'Application',
      path: '/application',
      open: true,
      heading: true,
      sort: true,
      routes: applicationComponents.map(item => {
        return {
          title: item.label,
          path: item.href
        }
      }),
      summarize: false
    },
    {
      title: 'Machine Learning',
      path: '/ml',
      open: true,
      heading: true,
      sort: true,
      routes: mlComponents.map(item => {
        return {
          title: item.label,
          path: item.href
        }
      }),
      summarize: false
    },
    {
      title: 'SwiftUI',
      path: '/swiftui',
      open: true,
      heading: true,
      sort: true,
      routes: swiftuiComponents.map(item => {
        return {
          title: item.label,
          path: item.href
        }
      }),
      summarize: false
    },
    {
      title: 'Terraform',
      path: '/terraform',
      open: true,
      heading: true,
      sort: true,
      routes: terraformComponents.map(item => {
        return {
          title: item.label,
          path: item.href
        }
      }),
      summarize: false
    },
    {
      title: 'Amazon EKS',
      path: '/eks',
      open: true,
      heading: true,
      sort: true,
      routes: eksComponents.map(item => {
        return {
          title: item.label,
          path: item.href
        }
      }),
      summarize: false
    },
    {
      title: 'Web',
      path: '/web',
      open: true,
      heading: true,
      sort: true,
      routes: webComponents.map(item => {
        return {
          title: item.label,
          path: item.href
        }
      }),
      summarize: false
    },
    {
      title: 'Data',
      path: '/Data',
      open: true,
      heading: true,
      sort: true,
      routes: dataComponents.map(item => {
        return {
          title: item.label,
          path: item.href
        }
      }),
      summarize: false
    },
    {
      title: 'Deep Learning',
      path: '/dl',
      open: true,
      heading: true,
      sort: true,
      routes: dlComponents.map(item => {
        return {
          title: item.label,
          path: item.href
        }
      }),
      summarize: false
    },
    {
      title: 'Programming',
      path: '/programming',
      open: true,
      heading: true,
      sort: true,
      routes: programmingComponents.map(item => {
        return {
          title: item.label,
          path: item.href
        }
      }),
      summarize: false
    }
  ]

  // check slug and return
  if (slug === '/') {
    return [routes[0]]
  }

  // architect
  if (slug.startsWith('/arch')) {
    return [routes[0]]
  }

  // serverless
  if (slug.startsWith('/serverless')) {
    return [routes[1]]
  }

  // application
  if (slug.startsWith('/application')) {
    return [routes[2]]
  }

  // ml
  if (slug.startsWith('/ml')) {
    return [routes[3]]
  }

  // swiftui
  if (slug.startsWith('/swiftui')) {
    return [routes[4]]
  }

  // terraform
  if (slug.startsWith('/terraform')) {
    return [routes[5]]
  }

  // eks
  if (slug.startsWith('/eks')) {
    return [routes[6]]
  }

  // web
  if (slug.startsWith('/web')) {
    return [routes[7]]
  }

  // data
  if (slug.startsWith('/data')) {
    return [routes[8]]
  }

  // dl
  if (slug.startsWith('/dl')) {
    return [routes[9]]
  }

  // programming
  if (slug.startsWith('/programming')) {
    return [routes[10]]
  }

  return routes
}

export default function DefaultLayout(props: {
  children: React.ReactNode
  frontMatter: any
  hidToc?: boolean
  maxWidth?: string
}) {
  const frontMatterSample = {
    slug: '/',
    title: 'Frontmatter Tittle',
    headings: [
      {
        level: 'h2',
        text: 'Setup',
        id: '123'
      } as Heading,
      {
        level: 'h2',
        text: 'Synthesize',
        id: '124'
      } as Heading,
      {
        level: 'h3',
        text: 'Deploy',
        id: '125'
      } as Heading
    ]
  }

  const { children, frontMatter, ...rest } = props

  const routes = getRoutes(frontMatter.slug)

  return (
    <PageContainer
      frontmatter={frontMatter}
      sidebar={<Sidebar routes={routes}></Sidebar>}
    >
      {children}
    </PageContainer>
  )
}
